import claimsService from "@/services/claims"; 
import accountsService from "@/services/accounts"; 

import allConstantsMixin from "@/mixins/allConstants";

export default {
    mixins: [allConstantsMixin],
    computed: {
        currentClaimInCreation() {
            return this.$store.getters["currentClaimInCreation/currentClaim"];
        },
    },
    methods: {
        startCurrentClaimInCreation(isRegisteredUser, fromPublicSite) {
            let initialStep = this.allConstants.claims.creationSteps.previousStep;
            if (fromPublicSite) {
                initialStep = isRegisteredUser ? this.allConstants.claims.creationSteps.collectInstitutionData : this.allConstants.claims.creationSteps.collectBasicData;
            }

            return this.currentClaimInCreationCreateOrUpdate({
                registeredUser: isRegisteredUser,
                fromPublicSite: fromPublicSite,
                currentStep: initialStep
            });
        },
        currentClaimInCreationCreateOrUpdate(claim) {
            return this.$store.dispatch("currentClaimInCreation/createOrUpdate", claim);
        },
        currentClaimInCreationClear() {
            return this.$store.dispatch("currentClaimInCreation/clear");
        },
        currentClaimInCreationGoForeward() {
            const actualClaimInCreation = this.currentClaimInCreation;
            actualClaimInCreation.currentStep++;
            return this.currentClaimInCreationCreateOrUpdate(actualClaimInCreation);
        },
        currentClaimInCreationGoBackward() {
            const actualClaimInCreation = this.currentClaimInCreation;
            actualClaimInCreation.currentStep--;
            return this.currentClaimInCreationCreateOrUpdate(actualClaimInCreation);
        },
        createRealClaim() {
            let channel;
            
            let claimantIdentificationNumber;
            let claimantName;

            let claimCreatorId;
            let claimCreatorRolId;
            
            const currentUserAccount = this.$Authentication.getAccount();
            const currentUserIsAuthenticated = currentUserAccount ? true : false;

            if (!currentUserIsAuthenticated) {
                //Aquí se sabe que el reclamo esta siendo creado por un usuario anonimo auto-regitrado en el paso 2, desde el sitio publico

                channel = this.allConstants.claims.chanelCodes.claimantApp; //front
                claimantIdentificationNumber = this.currentClaimInCreation.collectedBasicData.identificationNumber;
                claimantName = `${this.currentClaimInCreation.collectedBasicData.name} ${this.currentClaimInCreation.collectedBasicData.surname}`;

                claimCreatorId = this.currentClaimInCreation.collectedBasicData.accountId;
                claimCreatorRolId = this.allConstants.security.userRolesCodes.claimant;

            }
            else {
                //Aquí se sabe que el reclamo esta siendo creado por un usuario identificado (no-animo)
                //Puede ser un cliente reclamente autenticado o un usuario del WF

                if (currentUserAccount.roles.filter(role => role == this.allConstants.security.userRolesCodes.claimant).length > 0) {
                    channel = this.allConstants.claims.chanelCodes.claimantApp; //front
                    claimantIdentificationNumber = currentUserAccount.userName;
                    claimantName = currentUserAccount.visiblename;
                    
                    claimCreatorId = currentUserAccount.id;
                    claimCreatorRolId = this.allConstants.security.userRolesCodes.claimant;
                }

                if (currentUserAccount.roles.filter(role => 
                    role == this.allConstants.security.userRolesCodes.ddcExecutive ||
                    role == this.allConstants.security.userRolesCodes.ddcDefender).length > 0) {

                    channel = this.allConstants.claims.chanelCodes.ddcApp; //wf-ddc
                    claimantIdentificationNumber = this.currentClaimInCreation.collectedBasicData.identificationNumber;
                    claimantName = `${this.currentClaimInCreation.collectedBasicData.name} ${this.currentClaimInCreation.collectedBasicData.surname}`;

                    claimCreatorId = currentUserAccount.id;
                    claimCreatorRolId = currentUserAccount.roles[0];
                }

                if (currentUserAccount.roles.filter(role => 
                    role == this.allConstants.security.userRolesCodes.bankAdministrator ||
                    role == this.allConstants.security.userRolesCodes.bankExecutive).length > 0) {

                    channel = this.allConstants.claims.chanelCodes.bankApp; //wf-banco
                    claimantIdentificationNumber = this.currentClaimInCreation.collectedBasicData.identificationNumber;
                    claimantName = `${this.currentClaimInCreation.collectedBasicData.name} ${this.currentClaimInCreation.collectedBasicData.surname}`;

                    claimCreatorId = currentUserAccount.id;
                    claimCreatorRolId = currentUserAccount.roles[0];
                }
            }
            
            const claimData = {
                rut: claimantIdentificationNumber,
                name: claimantName,
                channel: channel,
                bank: {
                    code: this.currentClaimInCreation.collectedInstitutionData.bank,
                    branch: this.currentClaimInCreation.collectedInstitutionData.branch,
                    region: this.currentClaimInCreation.collectedInstitutionData.region,
                    province: this.currentClaimInCreation.collectedInstitutionData.province,
                    commune: this.currentClaimInCreation.collectedInstitutionData.commune,
                    executiveName: this.currentClaimInCreation.collectedInstitutionData.executiveName,
                    executiveEmail: this.currentClaimInCreation.collectedInstitutionData.executiveEmail,
                    executivePhone: this.currentClaimInCreation.collectedInstitutionData.executivePhone.toString(),
                },
                detail: {
                    productCodes: this.currentClaimInCreation.collectedProductData.products,
                    currencyType: this.currentClaimInCreation.collectedProductData.currency,
                    amount: this.currentClaimInCreation.collectedProductData.amount.toString(),
                    reason: this.currentClaimInCreation.collectedRequestData.eventDescription,
                    request: this.currentClaimInCreation.collectedRequestData.request,
                },
                documents: this.currentClaimInCreation.collectedDocumentsData.map(document => {
                    return {
                        blobId: document.blobId,
                        name: document.typeName,
                        fileName: document.realName,
                        contentType: document.blobContentType,
                        contentLenght: document.blobSize.toString(),
                    }
                })
            };

            return claimsService.create(claimData, claimCreatorId, claimCreatorRolId);
        },
        createAccount() {
            const accountData = {
                identificationNumber: this.currentClaimInCreation.collectedBasicData.identificationNumber,
                name: this.currentClaimInCreation.collectedBasicData.name,
                surname: this.currentClaimInCreation.collectedBasicData.surname,
                email: this.currentClaimInCreation.collectedBasicData.email,
                phone: this.currentClaimInCreation.collectedBasicData.phone.toString(),
                cellphone: this.currentClaimInCreation.collectedBasicData.cellphone.toString(),
                address: {
                    region: this.currentClaimInCreation.collectedAddressData.region,
                    city: this.currentClaimInCreation.collectedAddressData.province,
                    commune: this.currentClaimInCreation.collectedAddressData.commune,
                    streetAddress: this.currentClaimInCreation.collectedAddressData.street,
                    apartment: this.currentClaimInCreation.collectedAddressData.apartment,
                    building: this.currentClaimInCreation.collectedAddressData.building
                },
                bank: {
                    code: "0"
                },
                roleId: this.allConstants.security.userRolesCodes.claimant,
                includeCredentialCreation: true,
                passwordForCredential: this.currentClaimInCreation.collectedBasicData.secret,
                userType: this.currentClaimInCreation.userType
            };

            return accountsService.create(accountData);
        },
        updateAccount() {
            const accountData = {
                name: this.currentClaimInCreation.collectedBasicData.name,
                surname: this.currentClaimInCreation.collectedBasicData.surname,
                email: this.currentClaimInCreation.collectedBasicData.email,
                phone: this.currentClaimInCreation.collectedBasicData.phone.toString(),
                cellphone: this.currentClaimInCreation.collectedBasicData.cellphone.toString(),
                address: {
                    region: this.currentClaimInCreation.collectedAddressData.region,
                    city: this.currentClaimInCreation.collectedAddressData.province,
                    commune: this.currentClaimInCreation.collectedAddressData.commune,
                    streetAddress: this.currentClaimInCreation.collectedAddressData.street,
                    apartment: this.currentClaimInCreation.collectedAddressData.apartment,
                    building: this.currentClaimInCreation.collectedAddressData.building
                }
            };

            return accountsService.update(this.currentClaimInCreation.collectedBasicData.accountId, accountData);
        },
    }
};