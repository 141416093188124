<template>
  <div>
    <section
      class="g-bg-pos-center g-bg-size-cover"
      style="background-image: url(assets/img/fondo-ingreso-wide.jpg);height:300px;"
    >
      <div class="container g-color-white text-center g-py-210"></div>
    </section>
    <section class="container-fluid g-px-100--lg mt-5">
      <h1 class="g-font-weight-500 g-mb-15 font-color-title">
        Ingresar un reclamo
      </h1>
      <hr class="g-my-40" style="border: 1px solid;color: #555;!important" />
    </section>

    <div v-if="currentClaimInCreation.registeredUser">
      <section class="container-fluid g-px-100--lg mb-10">
        <div
          class="row"
          v-if="
            currentClaimInCreation.currentStep ==
              allConstants.claims.creationSteps.collectInstitutionData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-reclamo-registrado-1.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-reclamo-registrado-1-sm.svg"
            />
          </div>
        </div>
        <div
          class="row"
          v-if="
            currentClaimInCreation.currentStep >=
              allConstants.claims.creationSteps.collectProducts &&
              currentClaimInCreation.currentStep <=
                allConstants.claims.creationSteps.collectDocumentsData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-reclamo-registrado-2.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-reclamo-registrado-2-sm.svg"
            />
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <section class="container-fluid g-px-100--lg mb-10">
        <div
          class="row"
          v-if="
            currentClaimInCreation.currentStep ==
              allConstants.claims.creationSteps.collectBasicData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-reclamo-1.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-reclamo-1-sm.svg"
            />
          </div>
        </div>
        <div
          class="row"
          v-if="
            currentClaimInCreation.currentStep ==
              allConstants.claims.creationSteps.collectAddressData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-reclamo-2.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-reclamo-2-sm.svg"
            />
          </div>
        </div>
        <div
          class="row"
          v-if="
            currentClaimInCreation.currentStep ==
              allConstants.claims.creationSteps.collectInstitutionData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-reclamo-3.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-reclamo-3-sm.svg"
            />
          </div>
        </div>
        <div
          class="row"
          v-if="
            currentClaimInCreation.currentStep >=
              allConstants.claims.creationSteps.collectProducts &&
              currentClaimInCreation.currentStep <=
                allConstants.claims.creationSteps.collectDocumentsData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-reclamo-4.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-reclamo-4-sm.svg"
            />
          </div>
        </div>
      </section>
    </div>

    <collectorBasicData
      :userType="currentClaimInCreation.userType"
      :showMainButton="true"
      :showBackButton="true"
      :disableBackButton="true"
      :preloadData="currentClaimInCreation.collectedBasicData"
      @enter="endCollectBasicData"
      v-if="
        currentClaimInCreation.currentStep ==
          allConstants.claims.creationSteps.collectBasicData
      "
    />

    <collectorAddressData
      :userType="currentClaimInCreation.userType"
      :showMainButton="true"
      :showBackButton="true"
      :disableBackButton="false"
      :preloadData="currentClaimInCreation.collectedAddressData"
      @enter="endCollectAddressData"
      @back="currentClaimInCreationGoBackward"
      v-if="
        currentClaimInCreation.currentStep ==
          allConstants.claims.creationSteps.collectAddressData
      "
    />

    <collectorInstitutionData
      :userType="currentClaimInCreation.userType"
      :showMainButton="true"
      :showBackButton="true"
      :disableBackButton="true"
      :preloadData="currentClaimInCreation.collectedInstitutionData"
      @enter="endCollectInstitutionData"
      v-if="
        currentClaimInCreation.currentStep ==
          allConstants.claims.creationSteps.collectInstitutionData
      "
    />

    <collectorProductsData
      :userType="currentClaimInCreation.userType"
      :showMainButton="true"
      :showBackButton="true"
      :disableBackButton="false"
      :preloadData="currentClaimInCreation.collectedProductData"
      @enter="endCollectProductData"
      @back="currentClaimInCreationGoBackward"
      v-if="
        currentClaimInCreation.currentStep ==
          allConstants.claims.creationSteps.collectProducts
      "
    />

    <collectorRequestData
      :userType="currentClaimInCreation.userType"
      :showMainButton="true"
      :showBackButton="true"
      :disableBackButton="false"
      :preloadData="currentClaimInCreation.collectedRequestData"
      @enter="endCollectRequestData"
      @back="currentClaimInCreationGoBackward"
      v-if="
        currentClaimInCreation.currentStep ==
          allConstants.claims.creationSteps.collectRequestData
      "
    />

    <collectorDocumentsData
      :userType="currentClaimInCreation.userType"
      :showMainButton="true"
      :showBackButton="true"
      :disableBackButton="false"
      :includeTermsAndConditions="true"
      @back="currentClaimInCreationGoBackward"
      @enter="endCollectDocumentsData"
      @abort="restart"
      v-if="
        currentClaimInCreation.currentStep ==
          allConstants.claims.creationSteps.collectDocumentsData
      "
    />

    <section
      class="container-fluid g-px-140--lg g-px-50--sm mb-15"
      v-if="form.processing"
    >
      <div class="row">
        <div class="col-12">
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
        </div>
      </div>
    </section>

    <v-dialog v-model="showResultAccountCreation" persistent max-width="400">
      <v-card>
        <div class="pa-6">
          <div class="row">
            <div class="col-6"><img src="assets/img/message-3.svg" /></div>
            <div class="col-6">REGISTRO REALIZADO EXITOSAMENTE</div>
          </div>
          <div class="row">
            <div class="col-12">
              Su cuenta ha sido creada con éxito en Defensoria del Cliente.
            </div>
          </div>
          <div class="text-center">
            <v-btn
              class="btn-main mt-10"
              @click="
                showResultAccountCreation = false;
                currentClaimInCreationGoForeward();
              "
              >Continuar</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showResultClaimCreation" persistent max-width="400">
      <v-card>
        <div class="pa-6">
          <div class="row">
            <div class="col-6"><img src="assets/img/message-3.svg" /></div>
            <div class="col-6">
              SU RECLAMO HA SIDO ENVIADO CORRECTAMENTE CON EL FOLIO N°
              <span class="g-color-blue g-font-weight-600">{{
                claimCreatedNumber
              }}</span>
            </div>
          </div>
          <p class="mt-3">
            Su reclamo ha sido ingresado. Durante los próximos días se realizará
            la revisión de los antecedentes aportados. De faltar mayor
            información, tomaremos contacto con usted para que presente los
            datos faltantes en un plazo de 3 días hábiles. De no presentarlos,
            el reclamo será cerrado, debiendo presentar una nueva solicitud.
          </p>
          <v-btn class="btn-main mt-10" block @click="restart">Terminar</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import collectorBasicData from "@/components/PublicSite/User/collectorBasicData";
import collectorAddressData from "@/components/PublicSite/User/collectorAddressData";
import collectorInstitutionData from "@/components/PublicSite/User/collectorInstitutionData";
import collectorProductsData from "@/components/PublicSite/User/collectorProductsData";
import collectorRequestData from "@/components/PublicSite/User/collectorRequestData";
import collectorDocumentsData from "@/components/PublicSite/User/collectorDocumentsData";

import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimInCreationMixin from "@/mixins/currentClaimInCreation";

export default {
  mixins: [baseViewMixin, currentUserMixin, currentClaimInCreationMixin],
  components: {
    collectorBasicData,
    collectorAddressData,
    collectorInstitutionData,
    collectorProductsData,
    collectorRequestData,
    collectorDocumentsData,
  },
  data() {
    return {
      formFields: {},
      showResultAccountCreation: false,
      showResultClaimCreation: false,
      claimCreatedNumber: 0,
    };
  },
  methods: {
    async endCollectBasicData(collectedData) {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedBasicData = collectedData;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();
    },
    async endCollectAddressData(collectedData) {
      this.initSignalProcessing();

      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedAddressData = collectedData;

      const createAccountResult = await this.createAccount();
      currentClaimInCreation.collectedBasicData.accountId =
        createAccountResult.data.id;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);

      this.showResultAccountCreation = true;

      /* El boton cerrar del dialogo invoca a this.currentClaimInCreationGoForeward() para avanzar en el flujo */
      this.stopSignalProcessing();
    },
    async endCollectInstitutionData(collectedData) {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedInstitutionData = collectedData;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();
    },
    async endCollectProductData(collectedData) {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedProductData = collectedData;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();
    },
    async endCollectRequestData(collectedData) {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedRequestData = collectedData;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();
    },
    async endCollectDocumentsData(collectedData) {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedDocumentsData = collectedData;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();

      await this.createClaim();
    },
    async createClaim() {
      this.initSignalProcessing();
      try {
        const creationResult = await this.createRealClaim();

        this.claimCreatedNumber = creationResult.data.folio;
        this.showResultClaimCreation = true;
      } catch (ex) {
        this.showInfoNotification(
          "Estimado usuario, por políticas de seguridad, no se encuentra permitido crear reclamos de forma consecutiva. Favor intente más tarde."
        );
      }
      this.stopSignalProcessing();
    },
    restart() {
      this.currentClaimInCreationClear();

      

      if (this.currentUserIsAuthenticated) {
        this.replaceView("resume");
      } else {
        this.replaceView("begin");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.font-color-title {
  color: #555;
  font-size: 2.5rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
</style>
