<template>
  <v-form v-model="form.isValid" @submit.prevent="enter">
    <section class="container-fluid g-px-140--lg g-px-50--sm">
      <div class="row">
        <div class="col-sm-12 col-lg-12 g-px-10">
          <h4 class="h5 g-font-weight-700 g-mb-1">
            Información de la materia reclamada
          </h4>
          <div class="form-group g-mb-1 font-color-text">
            <label class="g-mb-10" for="inputGroup2_1"
              ><img src="assets/img/message.svg" /> Descripción de los hechos
              (máximo 4000 caracteres)</label
            >
            <v-textarea
              v-model.trim="formFields.suceso"
              required
              :rules="formFields.sucesoRules"
              maxlength="4000"
              outlined
              placeholder="Cuéntanos el motivo de tu reclamo"
              counter
            ></v-textarea>
          </div>

          <div class="form-group g-mb-1 font-color-text">
            <label class="g-mb-10" for="inputGroup2_1"
              ><img src="assets/img/message-2.svg" /> Petición concreta que se
              somete al conocimiento y resolución del defensor del cliente
              (máximo 512 caracteres)</label
            >
            <v-textarea
              v-model.trim="formFields.solicitud"
              required
              :rules="formFields.solicitudRules"
              maxlength="512"
              outlined
              placeholder="Indícanos cuál es tu petición para resolver el problema"
              counter
            ></v-textarea>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid text-center g-mb-60">
      <div class="row">
        <div class="col-6 col-xl-6 col-lg-6" v-if="showBackButton">
          <v-btn
            class="btn btn-lg btn-secondary g-mr-10 g-mb-15"
            :disabled="disableBackButton"
            :loading="form.processing"
            @click="back"
            >Volver</v-btn
          >
        </div>
        <div class="col-6 col-xl-6 col-lg-6" v-if="showMainButton">
          <v-btn
            type="submit"
            class="btn-main"
            :disabled="!form.isValid"
            :loading="form.processing"
            >Continuar</v-btn
          >
        </div>
      </div>
    </section>
  </v-form>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";

export default {
  mixins: [baseViewMixin],
  props: {
    userType: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showMainButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    preloadData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formFields: {
        suceso: "",
        sucesoRules: [
          (value) => !!value || "Requerido",
          (value) => value.length <= 4000 || "Demasiado largo",
          (value) =>
            this.allConstants.regex.regexComments.test(value) || "No es válido",
        ],
        solicitud: "",
        solicitudRules: [
          (value) => !!value || "Requerido",
          (value) => value.length <= 512 || "Demasiado largo",
          (value) =>
            this.allConstants.regex.regexComments.test(value) || "No es válido",
        ],
      },
    };
  },
  methods: {
    back() {
      this.$emit("back");
    },
    async enter() {
      this.initSignalProcessing();

      const collectedData = {
        eventDescription: this.formFields.suceso,
        request: this.formFields.solicitud,
      };

      this.$emit("enter", collectedData);
    },
  },
  beforeMount() {
    //Preload data
    if (this.preloadData) {
      this.formFields.suceso = this.preloadData.eventDescription;
      this.formFields.solicitud = this.preloadData.request;
    }
  },
};
</script>

<style lang="scss" scoped></style>
