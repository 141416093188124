<template>
  <v-form v-model="form.isValid" @submit.prevent="enter">
    <section class="container-fluid g-px-140--lg g-px-50--sm">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 g-px-10">
          <h4 class="h5 g-font-weight-700 g-mb-1">Documento Requerido</h4>
          <p>el formato de tus documentos puede ser Word,JPEG,PNG o PDF</p>

          <div class="card rounded-0">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr class="g-bg-cyan g-color-white">
                    <th>Documento</th>
                    <th>Nombre de documento</th>
                    <th>Estado</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="documento in formFields.documentosRequeridos"
                    :key="documento.typeName"
                  >
                    <td>
                      {{ documento.typeName }}
                      {{ documento.required ? "(*)" : "" }}
                      <sub>formatos permitidos (.doc,.docx,.jpg,jpeg,.pdf)</sub>
                    </td>
                    <td>
                      <v-file-input
                        v-model="documento.blob"
                        :accept="
                          documento.required
                            ? allowedRequiredFileTypes.join(',')
                            : allowedAlternativesFileTypes.join(',')
                        "
                        truncate-length="20"
                        :clearable="true"
                        :rules="
                          documento.required
                            ? formFields.documentosRequeridosRules
                            : formFields.documentosAlternativosRules
                        "
                        :disabled="form.processing"
                      >
                      </v-file-input>
                    </td>
                    <td>
                      <span v-if="!documento.loading">{{
                        documento.required
                          ? documento.blob == null
                            ? "Pendiente"
                            : "Seleccionado"
                          : ""
                      }}</span>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="blue-gre"
                      ></v-progress-circular>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="container-fluid text-center g-mb-60"
      v-if="!form.processing"
    >
      <div class="row">
        <div class="col-6 col-xl-6 col-lg-6" v-if="showBackButton">
          <v-btn
            class="btn btn-lg btn-secondary g-mr-10 g-mb-15"
            :disabled="disableBackButton"
            :loading="form.processing"
            @click="back"
            >Volver</v-btn
          >
        </div>
        <div class="col-6 col-xl-6 col-lg-6" v-if="showMainButton">
          <v-btn
            type="submit"
            class="btn-main"
            :disabled="!form.isValid"
            :loading="form.processing"
            >Continuar</v-btn
          >
        </div>
      </div>
    </section>

    <v-dialog
      v-model="showDialogTermsAndConditions"
      persistent
      max-width="600px"
    >
      <v-card>
        <div class="pa-6">
          <h4 class="g-mb-1 g-color-blue g-font-weight-600">
            DECLARACIÓN DEL CLIENTE
          </h4>
          <p class="g-font-size-14">
            Declaro conocer y aceptar el Reglamento de la Defensoría del
            Cliente, así como también los términos y condiciones de uso de la
            plataforma, aceptando tramitar mi reclamo a través de la misma.
          </p>
          <p class="g-font-size-14">
            Asimismo, autorizo a la Defensoría del Cliente y a sus partes
            relacionadas, a acceder y hacer uso de la información asociada a mi
            reclamo y a mi información personal, con la finalidad de favorecer
            el adecuado funcionamiento de la plataforma.
          </p>
          <p class="g-font-size-14">
            Declaro que la información proporcionada en este acto es verdadera y
            que estoy en conocimiento y acepto expresamente que –en caso de
            obtener una solución favorable a mi reclamo- el Banco tendrá derecho
            a exigir, como condición para el cumplimiento, la firma de una
            renuncia de acciones y finiquito en los términos definidos por la
            entidad bancaria.
          </p>

          <v-checkbox
            v-model="termsAndConditionsIsAcepted"
            :label="termsAndConditionsLabel"
          ></v-checkbox>
        </div>
        <v-card-actions>
          <v-btn
            class="btn btn-lg btn-secondary g-mr-10"
            :loading="form.processing"
            @click="abort"
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="btn-main"
            :disabled="!termsAndConditionsIsAcepted"
            :loading="form.processing"
            @click="enter"
            >Enviar reclamo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import attachmentsService from "@/services/attachments";

export default {
  mixins: [baseViewMixin],
  props: {
    userType: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showMainButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    preloadData: {
      type: Object,
      required: false,
      default: null,
    },
    includeTermsAndConditions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formFields: {
        documentosRequeridos: [],
        documentosRequeridosRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.isValidFileName(value) ||
            "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
          (value) =>
            this.isValidRequiredFileType(value) || "Formato incorrecto",
          (value) => this.isValidFileSize(value) || "Tamaño máximo 10 Mb",
        ],
        documentosAlternativosRules: [
          (value) =>
            this.isValidFileName(value) ||
            "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
          (value) =>
            this.isValidAlternativeFileType(value) || "Formato incorrecto",
          (value) => this.isValidFileSize(value) || "Tamaño máximo 10 Mb",
        ],
      },
      allowedRequiredFileTypes: [
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
                "image/gif",
                "image/jpeg",
                "image/jpg",
                "image/png"
      ],
      allowedAlternativesFileTypes: [
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
                "image/gif",
                "image/jpeg",
                "image/jpg",
                "image/png"
      ],
      showDialogTermsAndConditions: false,
      termsAndConditionsLabel: "Acepto la declaración del cliente",
      termsAndConditionsIsAcepted: false,
    };
  },
  methods: {
    isValidFileName(fileInfo) {
      if (fileInfo == null) return true;

      return this.allConstants.regex.regexFileNameComplex.test(fileInfo.name);
    },
    isValidFileSize(fileInfo) {
      if (fileInfo == null) return true;

      return fileInfo.size <= 10485760; //10 mg segun requerimiento como tamaño maximo
    },
    isValidRequiredFileType(fileInfo) {
      if (fileInfo == null) return true;
      if (fileInfo.size === 0) return false;

      return (
        this.allowedRequiredFileTypes.filter(
          (fileType) => fileType == fileInfo.type
        ).length > 0
      );
    },
    isValidAlternativeFileType(fileInfo) {
      if (fileInfo == null) return true;
      if (fileInfo.size === 0) return false;

      return (
        this.allowedAlternativesFileTypes.filter(
          (fileType) => fileType == fileInfo.type
        ).length > 0
      );
    },
    back() {
      this.$emit("back");
    },
    enter() {
      if (!this.includeTermsAndConditions) {
        this.finish();
        return;
      }

      if (!this.termsAndConditionsIsAcepted) {
        this.showDialogTermsAndConditions = true;
        return;
      }

      //terms & conditions was accepted
      this.showDialogTermsAndConditions = false;
      this.finish();
    },
    abort() {
      this.showDialogTermsAndConditions = false;
      this.termsAndConditionsIsAcepted = false;
    },
    async finish() {
      this.initSignalProcessing();

      const uploadFileColecction = [];

      this.formFields.documentosRequeridos.forEach((document) => {
        if (document.blob != null) {
          document.selected = true;
          document.realName = document.blob.name;
          document.fileSize = document.blob.size;
          document.fileContentType = document.blob.type;
          setTimeout(1000);
          uploadFileColecction.push(
            this.uploadDocument(document.internalId, document.blob)
          );

          document.loading = true;
        }
      });

      const uploadFilesResult = await Promise.all(uploadFileColecction);
      uploadFilesResult.forEach((uploadResult) => {
        this.formFields.documentosRequeridos.forEach((document) => {
          if (document.internalId == uploadResult.internalId) {
            document.blobId = uploadResult.blobId;
            document.loading = false;
            document.loaded = true;
          }
        });
      });

      const collectedData = this.formFields.documentosRequeridos
        .filter((document) => document.blobId != "")
        .map((document) => {
          return {
            typeName: document.typeName,
            realName: document.realName,
            blobId: document.blobId,
            blobSize: document.fileSize,
            blobContentType: document.fileContentType,
          };
        });

      this.$emit("enter", collectedData);
    },
    async uploadDocument(internalId, blob) {
      const addResult = await attachmentsService.add(blob);

      return {
        internalId: internalId,
        blobId: addResult.data.blobId,
      };
    },
  },
  async beforeMount() {
    this.initSignalLoading();

    const parameterStages = this.$Parameters.all.find(
      (parameter) => parameter.type == "WfStages"
    );
    const documentsRequired = parameterStages.values.find(
      (stage) => stage.code == "INGRESO"
    ).attachments;

    this.formFields.documentosRequeridos = documentsRequired.map((document) => {
      return {
        typeName: document.name,
        required: document.required,
        blobId: "",
        selected: false,
        loading: false,
        loaded: false,
        realName: "",
        internalId: this.$uuid.v4(),
        blob: null,
      };
    });

    this.stopSignalLoading();
  },
};
</script>

<style lang="scss" scoped></style>
