<template>
  <v-form v-model="form.isValid" @submit.prevent="enter">
    <section class="container-fluid g-px-140--lg g-px-50--sm">
      <div class="row">
        <div class="col-sm-12 col-lg-12 g-px-10">
          <div class="form-group mb-0">
            <div class="row">
              <div class="col-xl-4 col-lg-4 font-color-text">
                <p><img src="assets/img/bank.svg" /> Banco o institución</p>
                <div class="form-group g-mb-1">
                  <v-select
                    outlined
                    v-model.trim="formFields.banco"
                    :items="
                      banks.map((bank) => {
                        return { value: bank.code, text: bank.name };
                      })
                    "
                    placeholder="Selecciona uno de la lista"
                    required
                    :rules="formFields.bancoRules"
                    :disabled="banks.length == 0"
                    @change="resetRegionList"
                  ></v-select>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 font-color-text">
                <p><img src="assets/img/place.svg" /> Sucursal</p>
                <div class="form-group g-mb-1">
                  <div class="u-input-group-v2">
                    <v-text-field
                      outlined
                      v-model.trim="formFields.sucursal"
                      :rules="formFields.sucursalRules"
                      maxlength="30"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-4 col-lg-4 font-color-text">
                <p><img src="assets/img/map.svg" /> Región</p>
                <div class="form-group g-mb-1">
                  <div class="u-input-group-v2">
                    <v-select
                      outlined
                      v-model.trim="formFields.region"
                      :items="
                        regions.map((region) => {
                          return { value: region.code, text: region.name };
                        })
                      "
                      placeholder="Selecciona una de la lista"
                      required
                      :rules="formFields.regionRules"
                      :disabled="regions.length == 0"
                      @change="resetCommunesList"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 font-color-text">
                <p><img src="assets/img/map.svg" /> Comuna</p>
                <div class="form-group g-mb-1">
                  <v-select
                    outlined
                    v-model.trim="formFields.comuna"
                    :items="
                      communes.map((commune) => {
                        return { value: commune.code, text: commune.name };
                      })
                    "
                    placeholder="Selecciona una de la lista"
                    required
                    :rules="formFields.comunaRules"
                    :disabled="communes.length == 0"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <hr class="g-my-10" style="border: 1px solid;" />
          <div class="form-group g-pt-40">
            <div class="row">
              <div class="col-xl-4 col-lg-4 font-color-text">
                <p><img src="assets/img/name.svg" /> Ejecutivo</p>
                <div class="form-group g-mb-1">
                  <div class="u-input-group-v2">
                    <v-text-field
                      outlined
                      v-model.trim="formFields.ejecutivoNombre"
                      :rules="formFields.ejecutivoNombreRules"
                      maxlength="50"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 font-color-text">
                <p><img src="assets/img/mail.svg" /> Email ejecutivo</p>
                <div class="form-group g-mb-1">
                  <div class="u-input-group-v2">
                    <v-text-field
                      outlined
                      v-model.trim="formFields.ejecutivoEmail"
                      :rules="formFields.ejecutivoEmailRules"
                      maxlength="50"
                      type="email"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 font-color-text">
                <p>
                  <img src="assets/img/telephone.svg" /> Teléfono del ejecutivo
                </p>
                <div class="input-group g-mb-1">
                  <v-text-field
                    outlined
                    :label="'+56 ' + formFields.ejecutivoTelefono"
                    v-model.number="formFields.ejecutivoTelefono"
                    :rules="formFields.ejecutivoTelefonoRules"
                    maxlength="9"
                    type="phone"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid text-center g-mb-60">
      <div class="row">
        <div class="col-6 col-xl-6 col-lg-6" v-if="showBackButton">
          <v-btn
            class="btn btn-lg btn-secondary g-mr-10 g-mb-15"
            :disabled="disableBackButton"
            :loading="form.processing"
            @click="back"
            >Volver</v-btn
          >
        </div>
        <div class="col-6 col-xl-6 col-lg-6" v-if="showMainButton">
          <v-btn
            type="submit"
            class="btn-main"
            :disabled="!form.isValid"
            :loading="form.processing"
            >Continuar</v-btn
          >
        </div>
      </div>
    </section>
  </v-form>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";

import bankBranchesService from "@/services/bankBranches";

export default {
  mixins: [baseViewMixin, currentUserMixin],
  props: {
    userType: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showMainButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    preloadData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formFields: {
        banco: "",
        bancoRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexName.test(value) || "No es válido",
        ],
        sucursal: "",
        sucursalRules: [
          (value) =>
            value.length == 0
              ? true
              : this.allConstants.regex.regexName.test(value) || "No es válido",
        ],
        region: "",
        regionRules: [
          (value) => !!value || "Requerido",
          (value) => value != "" || "Requerido",
        ],
        provincia: "0",
        provinciaRules: [(value) => !!value || "Requerido"],
        comuna: "",
        comunaRules: [(value) => !!value || "Requerido"],
        ejecutivoNombre: "",
        ejecutivoNombreRules: [
          (value) =>
            value.length == 0
              ? true
              : this.allConstants.regex.regexName.test(value) || "No es válido",
        ],
        ejecutivoEmail: "",
        ejecutivoEmailRules: [
          (value) =>
            value.length == 0
              ? true
              : this.allConstants.regex.regexEmail.test(value) ||
                "No es válido",
        ],
        ejecutivoTelefono: "",
        ejecutivoTelefonoRules: [
          (value) => !!value || "Requerido",
          (value) => value.toString().length == 9 || "Debe contener 9 dígitos",
          (value) =>
            this.allConstants.regex.regexPhone.test(value) || "No es válido",
        ],
      },
      allRegions: [],
      regions: [],
      provinces: [],
      communes: [],
      banks: [],
      branches: [],
    };
  },
  methods: {
    back() {
      this.$emit("back");
    },
    async enter() {
      this.initSignalProcessing();

      const collectedData = {
        bank: this.formFields.banco,
        branch: this.formFields.sucursal == "" ? "-" : this.formFields.sucursal,
        region: this.formFields.region,
        province: this.formFields.provincia,
        commune: this.formFields.comuna,
        executiveName: this.formFields.ejecutivoNombre,
        executiveEmail: this.formFields.ejecutivoEmail,
        executivePhone: this.formFields.ejecutivoTelefono,
      };

      this.$emit("enter", collectedData);
    },
    async resetRegionList() {
      this.formFields.region = "";
      this.formFields.comuna = "";
      this.regions = [];

      if (this.formFields.banco == "") return;

      const getResult = await bankBranchesService.getByBankCode(
        this.formFields.banco
      );
      this.branches = getResult.data;

      const regionCodes = [];
      this.branches.forEach((branch) => {
        if (regionCodes.indexOf(branch.regionCode) < 0) {
          regionCodes.push(branch.regionCode);
        }
      });

      const filterdRegions = [];
      regionCodes.forEach((regionCode) => {
        const regionInfo = this.allRegions.find(
          (region) => region.code == regionCode
        );
        if (regionInfo) {
          filterdRegions.push({
            code: regionInfo.code,
            name: regionInfo.name,
          });
        }
      });

      this.regions = filterdRegions;
    },
    async resetCommunesList() {
      if (this.formFields.region == "") return;

      const filterdCommunes = [];
      const communesCodes = [];

      this.branches
        .filter((branch) => branch.regionCode == this.formFields.region)
        .forEach((branch) => {
          if (communesCodes.indexOf(branch.communeCode) < 0) {
            communesCodes.push(branch.communeCode);
          }
        });

      const regionInfo = this.allRegions.find(
        (region) => region.code == this.formFields.region
      );
      regionInfo.provinces.forEach((province) => {
        province.communes.forEach((commune) => {
          if (communesCodes.indexOf(commune.code) >= 0) {
            filterdCommunes.push(commune);
          }
        });
      });

      this.communes = filterdCommunes.sort((itemA, itemB) => {
        if (itemA.name < itemB.name) {
          return -1;
        }
        if (itemA.name > itemB.name) {
          return 1;
        }
        return 0;
      });
    },
  },
  async beforeMount() {
    this.initSignalLoading();

    //Get and prepare banks & regions list
    const parametersRegions = this.$Parameters.all.find(
      (parameter) => parameter.type == "Regions"
    );
    this.allRegions = parametersRegions.values;

    const parametersBank = this.$Parameters.all.find(
      (parameter) => parameter.type == "BankCodes"
    );
    this.banks = parametersBank.values;
    // Function to remove banks with codes "BAB" and "BFA"
    const removeBanks = (banks) => {
      return banks.filter(bank => bank.code !== "BAB" && bank.code !== "BFA");
    };

    //if the current user is someone from bank then filter only to its bank
    if (this.currentUserInfo != undefined) {
      if (
        this.currentUserInfo.roles[0] ==
          this.allConstants.security.userRolesCodes.bankAdministrator ||
        this.currentUserInfo.roles[0] ==
          this.allConstants.security.userRolesCodes.bankExecutive
      ) {
        this.banks = this.banks.filter(
          (bank) => bank.code == this.currentUserInfo.bank.code
        );
      }

      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.claimant
      ) {
        //  const index = this.banks.findIndex((bank) => bank.code == "BAB" || "BFA" );
        //  if(index >-1)
        //   this.banks.splice(index, 1);
        this.banks = removeBanks(this.banks);
      }
    } else {
       this.banks = removeBanks(this.banks);

    }

    //Preload data
    if (this.preloadData) {
      this.formFields.banco = this.preloadData.bank;
      this.formFields.sucursal = this.preloadData.branch;
      this.formFields.region = this.preloadData.region;
      this.formFields.provincia = this.preloadData.province;
      this.formFields.comuna = this.preloadData.commune;
      this.formFields.ejecutivoNombre = this.preloadData.executiveName;
      this.formFields.ejecutivoEmail = this.preloadData.executiveEmail;
      this.formFields.ejecutivoTelefono = this.preloadData.executivePhone;

      await this.resetRegionList();
      this.formFields.region = this.preloadData.region;

      await this.resetCommunesList();
      this.formFields.comuna = this.preloadData.commune;
    }

    this.stopSignalLoading();
  },
};
</script>

<style lang="scss" scoped>
.font-color-name {
  color: #555;
  font-size: 17.5px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.font-color-text {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #555;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}
.font-color-subtitle {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff !important;
}
</style>
