<template>
  <v-form v-model="form.isValid" @submit.prevent="enter">
    <section class="container-fluid g-px-140--lg g-px-50--sm">
      <div class="row">
        <div class="col-sm-12 col-lg-12 g-px-10">
          <h4 class="h5 g-font-weight-700 g-mb-10">
            Información del producto o servicio
          </h4>
          <p class="g-mb-30">Debes seleccionar un producto</p>
          <div class="g-mb-15 font-color-text">
            <div class="form-check-inline u-check g-pl-25" v-if="false">
              <v-checkbox
                v-for="producto in products"
                :key="producto.code"
                v-model="formFields.products"
                :rules="formFields.productsRules"
                :label="producto.name"
                :value="producto.code"
                class="ml-2"
              ></v-checkbox>
            </div>
            <div class="form-check-inline g-pl-25">
              <v-radio-group
                v-model="formFields.products"
                row
                :rules="formFields.productsRules"
              >
                <v-radio
                  v-for="producto in products"
                  :key="producto.code"
                  :label="producto.name"
                  :value="producto.code"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
          <div class="form-group g-pt-20">
            <div class="row">
              <div class="col-xl-4 col-lg-4 font-color-text">
                <p><img src="assets/img/money.svg" /> Monto reclamado</p>
                <div class="form-group g-mb-1">
                  <div class="u-input-group-v2">
                    <v-text-field
                      outlined
                      v-model.number="formFields.monto"
                      required
                      :rules="montoRulesCustom"
                      maxlength="8"
                      :label="formFields.monto | formatClassicNumber"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 font-color-text">
                <p><img src="assets/img/wallet.svg" /> Tipo de divisa</p>
                <div class="form-group g-mb-1">
                  <v-select
                    outlined
                    v-model.trim="formFields.divisa"
                    :items="currencies"
                    required
                    :rules="formFields.divisaRules"
                    :disabled="currencies.length == 0"
                  >
                  </v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid text-center g-mb-60">
      <div class="row">
        <div class="col-6 col-xl-6 col-lg-6" v-if="showBackButton">
          <v-btn
            class="btn btn-lg btn-secondary g-mr-10 g-mb-15"
            :disabled="disableBackButton"
            :loading="form.processing"
            @click="back"
            >Volver</v-btn
          >
        </div>
        <div class="col-6 col-xl-6 col-lg-6" v-if="showMainButton">
          <v-btn
            type="submit"
            class="btn-main"
            :disabled="!form.isValid"
            :loading="form.processing"
            >Continuar</v-btn
          >
        </div>
      </div>
    </section>
  </v-form>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";

export default {
  mixins: [baseViewMixin],
  props: {
    userType: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showMainButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    preloadData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formFields: {
        products: [],
        productsRules: [(value) => value.length > 0 || "Requerido"],
        monto: "",
        montoRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexDigits.test(value) || "No es válido",
        ],
        divisa: "",
        divisaRules: [
          (value) => !!value || "Requerido",
          (value) => value != "0" || "Requerido",
        ],
      },
      products: [],
      currencies: [],
    };
  },
  computed: {
    montoRulesCustom() {
      const currency = this.currencies.find(
        (c) => c.value == this.formFields.divisa
      );

      return [
        (value) => !!value || "Requerido",
        (value) =>
          this.allConstants.regex.regexDigits.test(value) || "No es válido",
        (value) =>
          value <= currency.max ||
          `no puede ingresar un monto mayor a ${currency.max} ${currency.text}`,
      ];
    },
  },
  watch: {
    "formFields.divisa"() {
      this.formFields.monto = "";
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
    async enter() {
      this.initSignalProcessing();

      const collectedData = {
        products: [this.formFields.products],
        amount: this.formFields.monto,
        currency: this.formFields.divisa,
      };

      this.$emit("enter", collectedData);
    },
  },
  async beforeMount() {
    this.initSignalLoading();

    //Get and prepare code list and currency list
    const parametersProducts = this.$Parameters.all.find(
      (parameter) => parameter.type == "ProductCodes"
    );
    this.products = parametersProducts.values;

    const parametersCurrencies = this.$Parameters.all.find(
      (parameter) => parameter.type == "CurrencyTypes"
    );
    this.currencies = parametersCurrencies.values.map((currency) => {
      return {
        value: currency.code,
        text: currency.name,
        max: currency.data.max,
      };
    });

    this.formFields.divisa = this.currencies[0].value;

    //Preload data
    if (this.preloadData) {
      this.formFields.products = this.preloadData.products;
      this.formFields.monto = this.preloadData.amount;
      this.formFields.divisa = this.preloadData.currency;
    }

    this.stopSignalLoading();
  },
};
</script>

<style lang="scss" scoped>
.font-color-name {
  color: #555;
  font-size: 17.5px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.font-color-text {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #555;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}
.font-color-subtitle {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff !important;
}
</style>
