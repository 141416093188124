import { render, staticRenderFns } from "./collectorInstitutionData.vue?vue&type=template&id=128d4541&scoped=true&"
import script from "./collectorInstitutionData.vue?vue&type=script&lang=js&"
export * from "./collectorInstitutionData.vue?vue&type=script&lang=js&"
import style0 from "./collectorInstitutionData.vue?vue&type=style&index=0&id=128d4541&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128d4541",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VForm,VSelect,VTextField})
